import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

const Container = styled.div`
  width: 100px;
  height: 2rem;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: 0.3s linear;
  font-size: 1rem;
  line-height: 2rem;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 36px;
    z-index: 10;
    transition: 0.6s linear;
    border-radius: 18px;
    background: ${({ theme }) => theme.colors.primary_dark};
  }

  button {
    flex: 1;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    transform: translateX(-100%);
    opacity: 0;
    transition: 0.3s linear;

    &:nth-of-type(1) {
      transition-delay: 1s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.8s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.6s;
    }
  }

  &:hover {
    transform: scale(1.1);

    span {
      transform: translateX(-100%);
      transition-delay: 0.3s;
    }

    button {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export default function Share(props) {
  const { title, url } = props;

  return (
    <Container>
      <span>
        <FontAwesomeIcon icon={faShareAlt} width="0" />
      </span>

      <FacebookShareButton url={url} separator=" ">
        <FontAwesomeIcon icon={faFacebookF} width="0" />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} separator=" ">
        <FontAwesomeIcon icon={faTwitter} width="0" />
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={title} separator=" ">
        <FontAwesomeIcon icon={faWhatsapp} width="0" />
      </WhatsappShareButton>
    </Container>
  );
}
