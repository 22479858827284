import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'react-moment';
import ReactHtmlParser from 'html-react-parser';
import Gallery from 'ground-web/components/v1/GridGallery';
import ContentRenderer from '../components/content-renderer';
import PageTitle from '../components/page-title';
import Article from '../components/article';
import SEO from '../components/seo';
import Share from '../components/share';
import { decodeHtml } from '../helpers';
import ConditionalRenderer from '../components/conditionalRenderer';
import { useAuth } from '../context';
import { useModal } from '../hooks';
import SignInContainer from '../components/signInContainer';

const PostHeader = styled.header`
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 80%;
    margin: 0 auto;
  }

  ${PageTitle} {
    margin-bottom: 1.5rem;

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
      margin-bottom: 2.5rem;
    }
  }
`;

const GallerySection = styled.section`
  margin-top: 4rem;
  overflow: visible;
`;

const DateLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function Post(props) {
  const { pageContext, location } = props;
  const { post } = pageContext;
  const {
    title,
    content,
    date,
    excerpt,
    galleryData: { gallery },
    featuredImage,
    postData: { exclusive },
  } = post;
  const { isLoggedIn } = useAuth();
  let galleryAuthor;
  let parsed;

  if (Array.isArray(gallery) && gallery.length > 0 && gallery[0]) {
    const {
      content: galleryContent,
      galleryData: { imagens: images },
    } = gallery[0];
    galleryAuthor = galleryContent;
    parsed = images
      .filter((img) => img.imagem)
      .map((img) => ({ source: img.imagem.sourceUrl }));
  }
  const showContent = isLoggedIn === undefined || isLoggedIn || !exclusive;
  const { isOpen, onClose, onOpen } = useModal(!showContent);
  return (
    <>
      <SEO
        title={decodeHtml(title)}
        description={decodeHtml(excerpt)}
        image={featuredImage?.node?.sourceUrl}
      />
      <Article>
        <PostHeader>
          <PageTitle>{decodeHtml(title)}</PageTitle>
          <DateLine>
            <p>
              <Moment locale="pt" format="DD/MM/YYYY">
                {date}
              </Moment>
            </p>
            <Share title={decodeHtml(title)} url={location.href} />
          </DateLine>
        </PostHeader>
        <ConditionalRenderer
          condition={showContent}
          renderOnSuccess={() => (
            <ContentRenderer content={content}>
              <ConditionalRenderer
                condition={parsed}
                renderOnSuccess={() => (
                  <GallerySection>
                    <h2>Imagens</h2>
                    {ReactHtmlParser(decodeHtml(galleryAuthor))}
                    <Gallery images={parsed} />
                  </GallerySection>
                )}
              />
            </ContentRenderer>
          )}
        />
        <SignInContainer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </Article>
    </>
  );
}

Post.propTypes = {
  data: PropTypes.shape({
    wordpressPost: PropTypes.any,
  }),
};
